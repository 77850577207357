import {ConceptModel, QuestionnaireModel} from "../../model";

export const urls = {
  home: "/quiz/",
  list: "/quiz/questionnaire",
}

export const ListBreadcrumb = [
  {
    label: "Quiz",
    url: urls.home
  },
  {
    label: "Questionnaire",
    url: "#"
  }
];


export const CreateBreadcrumb = [
  {
    label: "Quiz",
    url: urls.home
  },
  {
    label: "Questionnaire",
    url: urls.list
  },
  {
    label: "Create",
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: "Quiz",
    url: urls.home
  },
  {
    label: "Questionnaire",
    url: urls.list
  },
  {
    label: "Edit",
    url: '#'
  }
];

export const CopyBreadcrumb = [
  {
    label: "Quiz",
    url: urls.home
  },
  {
    label: "Questionnaire",
    url: urls.list
  },
  {
    label: "Copy",
    url: '#'
  }
];

export interface IState {
  id: number;
  copy?: boolean;
  questionnaire: QuestionnaireModel;
}

export const QuestionnaireRoles = [
  {
    name: "Player",
    id: 4,
    label: "Player",
    value: 4
  },
  {
    name: "Coach",
    id: 3,
    label: "Coach",
    value: 3
  }
] as any;