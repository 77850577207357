import React from "react";
import _ from "lodash";
import {CommonSubmitButton, LoaderType, RenderForm, showNotification, SwitchLoader} from "../../../common";
import { useHistory } from "react-router-dom";
import {AgeGroupModel, CompetenceModel, QuestionnaireModel, QuestionnaireStatement, QuestionnaireTypeModel, SportModel} from "../../../model";
import {IState, QuestionnaireRoles} from "../Data";
import {PillarService, QuestionnaireService, QuesTypeService, SportService} from "../../../services";
import {Questionnaires} from "./Questionnaire";

export const QuestionnaireForm = (props: IState) => {
  let history = useHistory();
  const [form, setForm] = React.useState({} as QuestionnaireModel);
  const [ageGroup, setAgeGroup] = React.useState([] as Array<AgeGroupModel>);
  const [sports, setSports] = React.useState([] as Array<SportModel>);
  const [competence, setCompetence] = React.useState([] as Array<CompetenceModel>);
  const [quesTypes, setQuesTypes] = React.useState([] as Array<QuestionnaireTypeModel>);

  const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const {name, value} = target;
    if(name === 'role_id' && value == 3) {
       // if questionninare for coach, set age group to 17+
      setForm({...form, ...{[name]: value, "age_group_id": 4}})
    } else {
      setForm({...form, [name]: value})
    }
    if(name === 'sport_id') {
      handleSportChange();
    }
  }

  function handleSportChange() {
    const item = form.questionnaire_statements;
    const newItm = item.map(q => {
      q.concept_id = 0;
      return q;
    });
   // updateQuestionnaire(item)
  }

  React.useEffect(() => {
    setForm(props.questionnaire)
  },[props.questionnaire])

  React.useEffect(() => {
     async function init() {
       const ageGroupList: Array<AgeGroupModel> = await QuestionnaireService.getAgeGroups();
       const competenceList: Array<CompetenceModel> = await PillarService.getCompetenceList();
       const sportsList: Array<SportModel> = await SportService.getList();
       const quesTypeList: Array<QuestionnaireTypeModel> = await QuesTypeService.getList();
       setAgeGroup(ageGroupList);
       setCompetence(competenceList);
       setSports(sportsList);
       setQuesTypes(quesTypeList);
     }
     init()
  },[])

    const FormInputs = [
      {
        "name" : "role_id",
        "value" : _.get(form, "role_id", ""),
        "onChange": handleChange,
        "required": true,
        "type": "select",
        "placeholder": "Choose User Role",
        "label": "User Role",
        "options": QuestionnaireRoles,
        "readonly": props.id !== undefined
      },
      {
        "name" : "questionnaire_type_id",
        "value" : _.get(form, "questionnaire_type_id", ""),
        "onChange": handleChange,
        "required": true,
        "type": "select",
        "placeholder": "Choose Questionnaire Type",
        "label": "Questionnaire Type",
        "options": quesTypes.filter(q => q.role_id == form.role_id),
        "readonly": props.id !== undefined
      },
      {
        "name" : "age_group_id",
        "value" : _.get(form, "age_group_id", ""),
        "onChange": handleChange,
        "required": true,
        "type": "select",
        "placeholder": "Choose age group",
        "label": "Age group",
        "options": ageGroup,
        "readonly": props.id !== undefined,
        "show": _.get(form, "role_id", "") == 4
      },
      {
        "name" : "sport_id",
        "value" : _.get(form, "sport_id", ""),
        "onChange": handleChange,
        "required": true,
        "type": "select",
        "placeholder": "Choose sport",
        "label": "Sport",
        "options": sports,
        "readonly": props.id !== undefined
      },
      {
        "name" : "title",
        "value" : _.get(form, "title", ""),
        "onChange": handleChange,
        "required": true,
        "placeholder": "Enter title",
        "label": "Title",
      },
    ];

    function isDisable() {
      const fields = FormInputs.reduce((acc, val) => acc ? acc : val.value === "" && val.required, false);
      if(!fields){
        return form.questionnaire_statements.reduce((acc, item) => acc ? acc : (
          ( _.isUndefined(item.statement_1) || item.statement_1 === "") ||
          ( _.isUndefined(item.statement_2) || item.statement_2 === "") ||
          ( _.isUndefined(item.statement_3) || item.statement_3 === "") ||
          ( _.isUndefined(item.statement_4) || item.statement_4 === "") ||
          ( _.isUndefined(item.question) || item.question === "") ||
          ( _.isUndefined(item.competence_id) || item.competence_id <= 0) ||
          ( _.isUndefined(item.concept_id) || item.concept_id <= 0 )

        ), false );
      }
      return fields;
    }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);

    if(props.id && props.copy === undefined){
      QuestionnaireService.updateQuestionnaire(form, props.id).then((message) => {
        showNotification("success", message);
        history.push(`/quiz/questionnaire`)
      }).catch(() => {});
    } else {
      QuestionnaireService.storeQuestionnaire(form).then((message) => {
        showNotification("success", message);
        history.push(`/quiz/questionnaire`)
      }).catch(() => {});
    }
  }

  const handleStatements = (index: number) => (event: React.ChangeEvent<HTMLElement>) => {
    const item = form.questionnaire_statements;
    const target: any = event.target;
    const {name, value} = target;
    item[index][name] = value;
    updateQuestionnaire(item)
  }

  function updateQuestionnaire(data:Array<QuestionnaireStatement>) {
    setForm({
      ...form,
      questionnaire_statements: data
    })
  }


  function addMore() {
    const questionnaire_statements = _.get(form, "questionnaire_statements", []);
    questionnaire_statements.push({} as QuestionnaireStatement)  ;
    updateQuestionnaire(questionnaire_statements);
  }

  function remove(num: number) {
    const {questionnaire_statements} = form;
    const updatedStatements: Array<QuestionnaireStatement> = questionnaire_statements.filter((item, index) => index !== num)
    updateQuestionnaire(updatedStatements);
  }

  if(_.isUndefined(form.questionnaire_statements)){
    addMore();
  }

  return(
      <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
        <div className="kt-portlet__body">
          <div className={'row'}>
            <RenderForm items={FormInputs}/>
            <Questionnaires form={form} ageGroup={form.age_group_id}  remove={remove} onChange={handleStatements} competence={competence}/>
            <div className={"col-md-12 col-lg-12"}>
              <div style={{textAlign: "end"}}>
                <button type={"button"} onClick={addMore} style={{marginTop:25}} className={"btn btn-primary btn-icon"}><i className={"fa fa-plus"}/></button>
              </div>
            </div>
          </div>
        </div>
        <CommonSubmitButton disabled={isDisable()}/>
      </form>
    )
}
