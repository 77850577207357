import React from "react";
import _, { get } from "lodash";
import {CommonSubmitButton, LoaderType, RenderForm, showNotification, SwitchLoader} from "../../../common";
import { useHistory } from "react-router-dom";
import {ClubModel, UserModel} from "../../../model";
import {IState} from "../Data";
import {ClubService} from "../../../services";
import { CropImage } from "../modal/CropImage";

export const ClubForm = (props: IState) => {
  let history = useHistory();
  const [show, setShow] = React.useState(false);
  const [form, setForm] = React.useState({} as ClubModel);
  const [coachDirectors, setCoachDirectors] = React.useState([] as Array<UserModel>);
  const [psychologist, setPsychologist] = React.useState([] as Array<UserModel>);
  const [physiotherapist, setPhysiotherapist] = React.useState([] as Array<UserModel>);

  const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'file' ? target.files[0] :  target.type === 'checkbox' ? target.checked ? 1 : 0 : target.value;
    const name = target.name;
    setForm({...form, [name]: value})
    if(target.type === 'file') {
      setShow(true)
    }
  }

  React.useEffect(() => {
    setForm(props.club)
  },[props.club])

  React.useEffect(() => {
    async function init() {
      const users: any = await ClubService.coachDirectorsList();
      setCoachDirectors(users.coaches);
      setPsychologist(users.psychologist);
      setPhysiotherapist(users.physiotherapist);
    }
    init()
  },[])



  const FormInputs = [
    {
      "name" : "name",
      "value" : _.get(form, "name", ""),
      "onChange": handleChange,
      "required": true,
      "placeholder": "Enter club name",
      "label": "Club name",
    },
    {
      "name" : "user_id",
      "value" : _.get(form, "user_id", ""),
      "onChange": handleChange,
      "required": true,
      "type": "select",
      "placeholder": "Select Coach Director",
      "label": "Club Coach Director",
      "options" : coachDirectors
    },
    {
      "name" : "psychologist_id",
      "value" : _.get(form, "psychologist_id", ""),
      "onChange": handleChange,
      //"required": true,
      "type": "select",
      "placeholder": "Select Psychologist",
      "label": "Club Psychologist",
      "options" : psychologist
    },
    {
      "name" : "physiotherapist_id",
      "value" : _.get(form, "physiotherapist_id", ""),
      "onChange": handleChange,
      //"required": true,
      "type": "select",
      "placeholder": "Select Physiotherapist",
      "label": "Club Physiotherapist",
      "options" : physiotherapist
    },
    {
      "name" : "file",
      //"value" : _.get(form, "file.name", ""),
      "onChange": handleChange,
      "type": "file",
      "label": "Logo",
      "file_path":  _.get(form, "logo", "")
    }
  ];

  // if(_.isUndefined(props.club)){
  //   FormInputs.push({
  //     "name" : "start_date",
  //     "value" : _.get(form, "start_date", ""),
  //     "onChange": handleChange,
  //     "required": true,
  //     "type": "date",
  //     "placeholder": "Club Session Start Date",
  //     "label": "Club Session Start Date"
  //   } as any)
  //   FormInputs.push({
  //     "name" : "end_date",
  //     "value" : _.get(form, "end_date", ""),
  //     "onChange": handleChange,
  //     "required": true,
  //     "type": "date",
  //     "placeholder": "Club Session End Date",
  //     "label": "Club Session End Date"
  //   } as any)
  // }

  const ThemePermit = {
    "name" : "can_update_settings",
    "value" : _.get(form, "can_update_settings", 0),
    "onChange": handleChange,
    "type": 'checkbox',
    "label": "Allow update club theme",
    "style": {height: 20},
    "checked": _.get(form, "can_update_settings", 0) === 1
  };

  function isDisable() {
    return FormInputs.reduce((acc, val) => acc ? acc : (val.value === "" || _.isNull(val.value)) && val.required, false);
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);

    if(props.id){
      ClubService.updateClub(form, props.id).then((message) => {
        showNotification("success", message);
        history.push(`/clubs/clubs`)
      }).catch(() => {});
    } else {
      ClubService.storeClub(form).then((message) => {
        showNotification("success", message);
        history.push(`/clubs/clubs`)
      }).catch(() => {});
    }
  }

    return(
        <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
          <div className="kt-portlet__body">
            <div className={'row'}>
              <RenderForm items={FormInputs}/>
              <label className="kt-checkbox kt-checkbox--brand">
                <input type="checkbox" {...ThemePermit}/> Enable to allow club to manage theme settings
                <span></span>
              </label>
            </div>
          </div>
          <CommonSubmitButton disabled={isDisable()}/>
          <CropImage
            show={show}
            file={get(form, "file", "")}
            type={'logo'}
            closeModal={(file) => {
              setShow(false);
              setForm({...form, 'logo': file } as any)
            }}
          />
        </form>
      )
}